<template>
	<div id="branchList" class="el-content">
		<a-button class="mb12" type="primary" v-has="{ action: 'sass_server_meal_add' }" @click="showEditMeal(0)">
			<i class="ri-add-line ri-top"></i>
			添加套餐
		</a-button>
		<a-table row-key="meal_id" :data-source="list" :columns="[
				{ title: 'ID', dataIndex: 'meal_id' },
				{ title: '套餐名称', dataIndex: 'meal_name' },
				{ title: '价格(元)', dataIndex: 'price' },
				{ title: '折扣价格(元)', dataIndex: 'discount_price' },
				{ title: '状态', dataIndex: 'is_put_away', slots: { customRender: 'is_put_away' } },
				{ title: '创建时间', dataIndex: 'create_time' },
				{ title: '操作', dataIndex: 'action', slots: { customRender: 'action' } }
			]"
		>
			<template #is_put_away="{record}">
				<a-tooltip :title="'点击设置为'+(record.is_put_away == 1 ?'下架':'上架')">
					<a-tag 
                        @click="changePutAway(record.meal_id)" 
                        :color="record.is_put_away == 1 ?'#00CC66':'#FF6633'" 
                    >
                    {{ record.is_put_away == 1 ?'上架':'下架'}}
                    </a-tag>
				</a-tooltip>
				<!-- <a-tooltip title="点击设置上架">
					<a-tag 
                        @click="changePutAway(record.meal_id)" 
                        color="#FF6633" 
                        v-if="record.is_put_away == 0">已下架
                    </a-tag>
				</a-tooltip> -->
			</template>
			<template #action="{record}">
                <a-space>
                    <kd-button 
                        type="primary" 
                        icon="ri-edit-line" 
                        title="编辑" 
                        @click="showEditMeal(record.meal_id)"
                        v-has="{ action: 'sass_server_meal_add' }">
                    </kd-button>
                    <kd-button 
                        type="danger" 
                        icon="ri-delete-bin-line" 
                        title="删除" 
                        @click="deleteMeal(record.meal_id)"
                        v-has="{ action: 'sass_server_meal_del' }">
                    </kd-button>
                </a-space>
			</template>
		</a-table>
		<a-modal title="新增/编辑分支信息" 
            v-model:visible="mealState.show" 
            width="700px" 
            @ok="saveMeal" 
            @cancel="mealState.show = false"
        >
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
				<a-form-item label="选择分支">
					<com-server-select 
                        :server="mealState.server" 
                        @success="e=>mealState.form.server_id = e">
                    </com-server-select>	
				</a-form-item>
				<a-form-item label="套餐名称" required>
					<a-input v-model:value="mealState.form.meal_name"></a-input>
				</a-form-item>
				<a-form-item label="价格">
					<a-input v-model:value="mealState.form.price"></a-input>
				</a-form-item>
				<a-form-item label="折扣价格">
					<a-input v-model:value="mealState.form.discount_price"></a-input>
				</a-form-item>
				<a-form-item label="可创建应用数量">
					<a-input v-model:value="mealState.form.limit"></a-input>
				</a-form-item>
				<a-form-item label="是否上架">
					<a-radio-group v-model:value="mealState.form.is_put_away">
						<a-radio :value="1">是</a-radio>
						<a-radio :value="0">否</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="描述" >
					<a-textarea v-model:value="mealState.form.description" :rows="4" />
				</a-form-item>
				<a-form-item label="排序">
					<a-input v-model:value="mealState.form.rank"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRaw, toRefs } from 'vue'
import serviceModel from '@/api/saas/service.js'
import comServerSelect from '@/components/admin/com-server-select.vue'
export default{
	components:{
		comServerSelect
	},
	setup(){
		const _d = reactive({
			list:[],
			showEditDialog:false,
			form:null,
			branchList:[],
			server:null
		})
		getMealList()
		function getMealList(){
			serviceModel.getBranch("",2,res=>_d.list = res)
		}
		
		
        let mealState = useEditMeal({getMealList})
		return{
			...toRefs(_d),
			getMealList,
            ...mealState
		}
	}
}

//套餐编辑操作
function useEditMeal(obj){
    let mealState = reactive({
        show:false,
        form:null,
        server:null
    })

    function showEditMeal(id){
        if( id ){
            serviceModel.getBranchDetail(id,2,res=>{
                mealState.form = {
                    rank:res.rank,
                    price:res.price,
                    limit:res.limit,
                    server_id:res.server_id,
                    meal_id:res.meal_id,
                    meal_name:res.meal_name,
                    description:res.description,
                    is_put_away:res.is_put_away,
                    discount_price:res.discount_price,
                }

                //回显使用
                serviceModel.getBranchDetail(res.server_id,1,resutl=>mealState.server = resutl)
                mealState.show = true
            })
            return
        }
        mealState.form = {
            rank: '',
            price: '',
            limit: '',
            meal_id: 0,
            server_id: '',
            is_put_away: 0,
            meal_name: '',
            description: '',
            discount_price: ''
        }
        mealState.show = true
    }

    function saveMeal(){
        serviceModel.addOrEditMeal( toRaw(mealState.form) ,()=>{
            mealState.show = false
            obj.getMealList()
        })
    }
    const changePutAway = (id)=>serviceModel.handleMeal(id,2,()=>obj.getMealList())
    const deleteMeal = (id)=>serviceModel.handleMeal(id,1,()=>obj.getMealList())

    return{ mealState ,showEditMeal,saveMeal,deleteMeal,changePutAway }
}

</script>

<style>
</style>
